<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-4">
            <div class="card h-100">
              <div class="card-body">
                <div class="text-bold">
                  Сервер backend
                </div>
                <div class="mt-2">
                  <div>{{ info.load_average }}</div>
                  <div class="mt-1">{{ info.disk_usage }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="card h-100">
              <div class="card-body">
                <div class="text-bold">
                  Показать логи
                </div>
                <div class="mt-2">
                  <div @click="getLogCrm" class="btn btn-sm btn-info">Логи CRM</div>
                  <div @click="getLogBackend" class="btn btn-sm btn-info ml-1">Логи Copix</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="card h-100">
              <div class="card-body">
                <div class="text-bold">
                  Заблокированные IP
                </div>
                <div class="mt-2">
                  <div v-if="info.block_ip && info.block_ip.length === 0" class="">-</div>
                  <div v-if="info.block_ip && info.block_ip.length > 0">
                    <div v-for="ip in info.block_ip" :key="ip.ip">{{ ip.ip }}</div>
                  </div>
                </div>
                <div class="mt-2">
                  <div @click="showAddBlockPopup" class="btn btn-sm btn-info">Добавить IP</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <popup
      :closeButton="logCrmPopup.closeButton"
      :show="logCrmPopup.show"
      :size="'lg'"
      @closePopup="closeLogCrmPopup"
    >
      <div slot="header">Логи errors.log сервера CRM</div>
      <div slot="body">
        <div class="log-line" v-for="(item, key) in logCrm" :key="key">{{ item }}</div>
      </div>
    </popup>


    <popup
      :closeButton="logBackendPopup.closeButton"
      :show="logBackendPopup.show"
      :size="'lg'"
      @closePopup="closeLogBackendPopup"
    >
      <div slot="header">Логи errors.log сервера Copix</div>
      <div slot="body">
        <div class="log-line" v-for="(item, key) in logBackend" :key="key">{{ item }}</div>
      </div>
    </popup>


    <popup
      :closeButton="addBlockIpPopup.closeButton"
      :show="addBlockIpPopup.show"
      :actionButton="addBlockIpPopup.actionButton"
      :actionClass="addBlockIpPopup.actionClass"
      @closePopup="closeAddBlockPopup"
      @actionPopup="addBlockIpSubmit"
    >
      <div slot="header">Добавить IP в блокировку</div>
      <div slot="body">
        <div class="form-group">
          <label for="ip">IP</label>
          <input
              id="ip"
              v-model="newBlockIp.ip"
              type="text"
              class="form-control form-control-sm"
          />
        </div>
        <div class="form-group">
          <label for="block_minutes">На сколько минут заблокировать</label>
          <input
              id="block_minutes"
              v-model="newBlockIp.blockMinutes"
              type="number"
              class="form-control form-control-sm"
          />
        </div>
      </div>
    </popup>
    
  </Layout>
</template>


<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import axios from "axios";
import Popup from "@/components/Popup";

/**
 * Rbac component
 */
export default {
  components: {
    Popup,
    Layout,
    PageHeader,
  },
  data() {
    return {
      info: {},
      logCrm: [],
      logBackend: [],
      newBlockIp: {
        ip: '',
        blockMinutes: 0
      },
      logCrmPopup: {
        show: false,
        closeButton: "Закрыть",
      },
      logBackendPopup: {
        show: false,
        closeButton: "Закрыть",
      },
      addBlockIpPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.add'),
        actionClass: "btn-success"
      },
      title: this.$t('menuitems.admin.list.servers'),
      items: [
        {
          text: this.$t('menuitems.admin.text'),
        },
        {
          text: this.$t('menuitems.admin.list.servers'),
          active: true
        }
      ]
    };
  },
  validations: {
    
  },
  computed: {
    
  },
  methods: {
    closeAddBlockPopup(){
      this.addBlockIpPopup.show = false;
    },
    showAddBlockPopup(){
      this.addBlockIpPopup.show = true;
    },
    addBlockIpSubmit(){
      this.$store.dispatch('startPreloader');
      let formData = new FormData();
      formData.append('ip', this.newBlockIp.ip);
      formData.append('minutes', this.newBlockIp.blockMinutes);

      axios
        .post(`/v1/servers/block-ip`, formData)
        .then(() => {
          this.$store.dispatch("addNotification", {
            text: 'IP добавлен в блокировку',
            time: 3,
            color: "success"
          });
          this.closeAddBlockPopup();
          this.newBlockIp.ip = '';
          this.newBlockIp.blockMinutes = '';
          this.getInfo();
          this.$store.dispatch('stopPreloader');
        })
        .catch(err => {
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
          this.$store.dispatch('stopPreloader');
        });
    },
    getInfo(){
      axios
          .get(`/v1/servers/info`)
          .then(resp => {
            this.info = resp.data;
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },  
    getLogCrm(){
      this.$store.dispatch('startPreloader');
      axios
        .get(`/v1/servers/log-crm`)
        .then(resp => {
          this.logCrm = resp.data;
          this.logCrmPopup.show = true;
          this.$store.dispatch('stopPreloader');
        })
        .catch(err => {
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
          this.$store.dispatch('stopPreloader');
        });
    },
    closeLogCrmPopup(){
      this.logCrmPopup.show = false;
    },
    closeLogBackendPopup(){
      this.logBackendPopup.show = false;
    },
    getLogBackend(){
      this.$store.dispatch('startPreloader');
      axios
        .get(`/v1/servers/log-backend`)
        .then(resp => {
          this.logBackend = resp.data;
          this.logBackendPopup.show = true;
          this.$store.dispatch('stopPreloader');
        })
        .catch(err => {
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
          this.$store.dispatch('stopPreloader');
        });
    }
  },
  created() {
    this.getInfo();
  }
};
</script>

<style scoped lang="scss">
  .log-line{
    margin-top: 2px;
    color: gray;
    font-size: 13px;
  }
</style>